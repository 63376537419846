/**
 * Отчет
 */

export default [
  {
    path: "/report",
    component: () => import("@/pages/Reports/Index.vue"),
    name: "Report",
    redirect: { name: "ReportSellsV2" },
    meta: {
      title: 'Отчеты',
      checkAvailableService: `${import.meta.env.VITE_APP_APFS_URL}/ping?db=ext`,
      checkAvailableWithTariff: ['IN_WB', 'IN_OZ'],
    },
    children: [
      {
        path: "v1/sale",
        component: () => import("@/pages/Reports/versions/v1/Sells.vue"),
        name: "ReportSellsV1",
        meta: {title: 'Отчет о заказах'},
      },
      {
        path: "weekly",
        component: () => import("@/pages/Reports/versions/v1/SellsWeekly.vue"),
        name: "ReportWeekly",
        meta: {title: 'Отчет по недельно'},
      },
      {
        path: "dynamics",
        component: () => import("@/pages/Reports/versions/v1/ReportWithDynamics.vue"),
        name: "ReportWithDynamics",
        meta: {title: 'Отчет с динамикой'},
      },
      {
        path: "by-days",
        component: () => import("@/pages/Reports/versions/v1/ReportByDays.vue"),
        name: "ReportByDays",
        meta: {title: 'Отчет по дням'},
      },
      {
        path: "order-creation-time",
        component: () => import("@/pages/Reports/versions/v1/ReportOrderCreationTime.vue"),
        name: "ReportOrderCreationTime",
        meta: {title: 'Время создания заказов'},
      },
      {
        path: "sales-funnel",
        component: () => import("@/pages/Reports/versions/v1/SalesFunnel/Index.vue"),
        name: "ReportSalesFunnel",
        meta: {title: 'Воронка продаж'},
      },
      // new reports
      {
        path: "v2/sale",
        component: () => import("@/pages/Reports/versions/v2/Sells.vue"),
        name: "ReportSellsV2",
        meta: {title: 'Отчет о заказах' },
      },
      {
        path: "v2/days",
        component: () => import("@/pages/Reports/versions/v2/Dynamics.vue"),
        name: "ReportDaysV2",
        meta: {title: 'Отчет по дням' },
      },
      {
        path: "v2/weeks",
        component: () => import("@/pages/Reports/versions/v2/Dynamics.vue"),
        name: "ReportWeeksV2",
        meta: {title: 'Отчет по неделям' },
      },
      {
        path: "v2/months",
        component: () => import("@/pages/Reports/versions/v2/Dynamics.vue"),
        name: "ReportMonthsV2",
        meta: {title: 'Отчет по месяцам' },
      },
    ],
  },
  // Отчет ABC анализ
  {
    path: "/report-abc",
    component: () => import("@/pages/Reports/Index.vue"),
    name: "ReportABC",
    redirect: {name: "ReportABCAnalysis"},
    meta: {
      title: 'Отчеты',
      checkAvailableService: `${import.meta.env.VITE_APP_APFS_URL}/ping?db=ext`,
      checkAvailableWithTariff: ['IN_WB', 'IN_OZ'],
    },
    children: [
      {
        path: "abc",
        component: () => import("@/pages/Reports/versions/v1/ReportABCAnalysis.vue"),
        name: "ReportABCAnalysis",
        meta: {title: "Отчет по ABC-анализу"},
      },
      {
        path: "compare-goods",
        component: () => import("@/pages/Reports/versions/v1/ReportABCCompareGoods.vue"),
        name: "ReportABCCompareGoods",
        meta: {title: "Отчет по сравнению товаров"},
      },
    ],
  },
]
